import { useStaticQuery, graphql } from 'gatsby'

export const ServicesDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allServicesDataJson, images } = useStaticQuery(
    graphql`
      query queryServicesDataContent {
        allServicesDataJson {
          edges {
            node {
              main_text {
                title
                content
                background_text
              }
              items {
                title
                content
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "services" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 600, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allServicesDataJson.edges[0].node.main_text,
    allServicesDataJson.edges[0].node.items,
    images.edges
  ]
}
