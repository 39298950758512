import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import { replaceStringWithDates } from '@scripts/helpers'

import { PromoDataContent } from './models'

import PromoImage from '@images/promo/image.png'

const Promo = styled(({ className }) => {
  const mainText = PromoDataContent()[0]

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          <Col xs={12} xl={6} className="p-xl-0">
            <div
              className="image"
              style={{ backgroundImage: `url(${PromoImage})` }}
            />
          </Col>
          <Col xs={12} xl={6} className="p-xl-0">
            <div className="content">
              <h5>{mainText.title}</h5>
              <h6>{mainText.subtitle}</h6>
              <p>{mainText.content}</p>
              <a
                href={replaceStringWithDates(mainText.cta.link)}
                target="_blank"
                rel="noreferrer"
              >
                {mainText.cta.text}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  position: relative;
  margin: 62px 0 60px;

  @media (min-width: 768px) {
    margin: 94px 0 100px;
  }

  @media (min-width: 1440px) {
    margin: 220px 0 106px;
  }

  .image {
    background-size: cover;
    background-position: center center;
    height: 200px;

    @media (min-width: 768px) {
      height: 330px;
    }

    @media (min-width: 1440px) {
      height: 100%;
    }
  }

  .content {
    background: #f2f1ec;
    padding: 30px 20px;

    @media (min-width: 768px) {
      padding: 46px 40px 40px;
    }

    @media (min-width: 1440px) {
      padding: 67px 78px 96px;

      &:before {
        background: #f2f1ec;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: 100%;
      }
    }

    h5 {
      color: #d64a00;
      font-family: Philosopher;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      letter-spacing: 0.01em;
      line-height: 40px;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 56px;
      }

      @media (min-width: 1440px) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    h6 {
      color: #000000;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin: 20px 0;
    }

    p {
      color: #434852;
      font-size: 20px;
      letter-spacing: 0.01em;
      line-height: 30px;
      margin-bottom: 20px;

      @media (min-width: 1440px) {
        margin-bottom: 30px;
      }
    }

    a {
      background: #d67401;
      border: none;
      color: #ffffff;
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 52px;
      text-align: center;
      width: 100%;

      &:hover {
        background: #d64a00;
      }

      @media (min-width: 768px) {
        padding: 0 97px;
        width: auto;
      }
    }
  }
`

export default Promo
