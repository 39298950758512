import React from 'react'
import styled from '@emotion/styled'

// components
import SEO from './components/Seo'
import Hero from './components/Hero'
import Gallery from './components/Gallery'
import Services from './components/Services'
import Quote from './components/Quote'
import Equipped from './components/Equipped'
import Proximity from './components/Proximity'
import Promo from './components/Promo'
import Faq from './components/Faq'

import TextureBottom from '@images/background/texture-bottom.png'
import TextureTop from '@images/background/texture-top.png'

const Home = styled(({ className }) => {
  return (
    <div className={`${className}`}>
      <SEO />
      <Hero />
      <Gallery />
      <div className="background-area">
        <img src={TextureTop} alt="Top" className="texture-top" />
        <img src={TextureBottom} alt="Bottom" className="texture-bottom" />
        <Services />
        <Quote />
        <Equipped />
      </div>
      <Proximity />
      <Promo />
      <Faq />
    </div>
  )
})`
  .background-area {
    background: #f2f1ec;
    padding-bottom: 65px;
    padding-top: 120px;
    position: relative;

    @media (min-width: 1440px) {
      padding-bottom: 150px;
      padding-top: 220px;
      margin-top: 0;
    }

    .texture-top {
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
    }

    .texture-bottom {
      bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
`

export default Home
