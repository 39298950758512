import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
//import BackgroundImage from 'gatsby-background-image'

import { ServicesDataContent } from './models'
import AccordionBox from './components/AccordionBox'

const Services = styled(({ className }) => {
  const mainText = ServicesDataContent()[0]
  const items = ServicesDataContent()[1]
  const images = ServicesDataContent()[2]

  return (
    <div className={`${className}`}>
      <div className="background-text">
        <span>{mainText.background_text[0]}</span>
        <span>{mainText.background_text[1]}</span>
      </div>
      <Container>
        <div className="title">
          <h3>{mainText.title}</h3>
          <p>{mainText.content}</p>
        </div>
        <div className="card-list d-none d-md-block">
          <Row>
            {items.map((item, index) => {
              return (
                <Col md={12} xl={4} key={index} className="card-wrap">
                  <div className="card">
                    <div
                      className="image background-image"
                      style={{
                        backgroundImage: `url(${images[index].node.childImageSharp.fluid.srcWebp})`
                      }}
                    />
                    <div className="content">
                      <h5>{item.title}</h5>
                      <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
        <div className="d-md-none">
          <AccordionBox data={items} images={images} />
        </div>
      </Container>
    </div>
  )
})`
  position: relative;

  .background-text {
    display: none;
    position: absolute;
    top: 15%;
    z-index: 0;

    @media (min-width: 1200px) {
      display: block;
    }

    span {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #fff;
      color: transparent;
      display: block;
      font-family: Philosopher;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.01em;
      line-height: 0.6;
      position: relative;
      white-space: nowrap;

      &:first-of-type {
        font-size: 406.904px;
        left: -40px;
      }

      &:last-child {
        font-size: 317.439px;
        text-align: center;
      }
    }
  }

  .title {
    h3 {
      color: #d64a00;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 52px;
      }

      @media (min-width: 1440px) {
        font-size: 62px;
        line-height: 72px;
      }
    }

    p {
      color: #434852;
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 20px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .card-wrap {
    margin-bottom: 40px;

    @media (min-width: 1440px) {
      margin-bottom: 32px;
    }
  }

  .card-list {
    .card {
      background: rgb(255, 255, 255);
      display: flex;

      @media (min-width: 1200px) {
        display: block;
        height: 100%;
      }

      .image {
        flex: 0 0 230px;
        height: auto;
        min-height: 100%;
        max-width: 230px;

        @media (min-width: 1200px) {
          height: 312px;
          min-height: 312px;
          max-width: 100%;
        }
      }

      .content {
        flex: 0 0 calc(100% - 230px);
        margin-top: 0px;
        min-height: unset;
        max-width: calc(100% - 230px);
        transform: none;
        padding: 40px;

        @media (min-width: 1200px) {
          padding: 40px 23.5px;
        }

        h5 {
          font-weight: bold;
          font-size: 24px;
          line-height: 40px;
        }

        p {
          font-size: 20px;
          line-height: 30px;
          margin: 0;

          br {
            content: '';
            display: block;
            margin: 20px 0;
          }
        }

        @media (min-width: 1200px) {
          max-width: none;
        }
      }
    }
  }
`

export default Services
