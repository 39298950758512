import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import { replaceStringWithDates } from '@scripts/helpers'

import { GalleryDataContent } from './models'

const Gallery = styled(({ className }) => {
  const mainText = GalleryDataContent()[0]
  const itemTitle = GalleryDataContent()[1]
  const items = GalleryDataContent()[2]
  const images = GalleryDataContent()[3]

  let firstColItems = items.slice(0, 2)
  let secondColItems = items.slice(2, 4)

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          <Col xs={12} xl={10}>
            <div className="title">
              <h3>{mainText.title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={10}>
            <div className="text">
              <p>{mainText.content}</p>
            </div>
          </Col>
          <Col xs={12} xl={2}>
            <a
              href={replaceStringWithDates(mainText.cta.link)}
              target="_blank"
              rel="noreferrer"
            >
              {mainText.cta.text}
            </a>
          </Col>
          <Col xl={6} className="d-none d-xl-block">
            {firstColItems.map((item, index) => {
              return (
                <div key={index} className="card">
                  <div
                    className={`image image-${index} background-image`}
                    style={{
                      backgroundImage: `url(${images[index].node.childImageSharp.fluid.srcWebp})`
                    }}
                  ></div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>
              )
            })}
          </Col>
          <Col xl={6} className="d-none d-xl-block">
            <div className="card-title">
              <h4>{itemTitle.title}</h4>
              <p>{itemTitle.subtitle}</p>
            </div>

            {secondColItems.map((item, index) => {
              return (
                <div key={index} className="card">
                  <div
                    className={`image image-${index} background-image`}
                    style={{
                      backgroundImage: `url(${
                        images[index + 2].node.childImageSharp.fluid.srcWebp
                      })`
                    }}
                  ></div>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>
              )
            })}
          </Col>
          <Col className="d-xl-none">
            <div className="card-title">
              <h4>{itemTitle.title}</h4>
              <p>{itemTitle.subtitle}</p>
            </div>

            {items.map((item, index) => {
              return (
                <div key={index} className="card">
                  <div
                    className={`image image-${index} background-image`}
                    style={{
                      backgroundImage: `url(${images[index].node.childImageSharp.fluid.srcWebp})`
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>
              )
            })}
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  position: relative;
  margin-top: 60px;

  @media (min-width: 1200px) {
    margin-top: 184px;
  }

  .title {
    h3 {
      color: #d64a00;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 52px;
      }

      @media (min-width: 1440px) {
        font-size: 62px;
        line-height: 72px;
      }
    }
  }

  .text {
    margin-bottom: 20px;

    p {
      color: #434852;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
    }

    @media (min-width: 1440px) {
      margin-bottom: 40px;
    }
  }

  a {
    background: #d67401;
    border: none;
    color: #ffffff;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 52px;
    padding: 0 33px;
    text-align: center;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
    }

    &:hover {
      background: #d64a00;
    }
  }

  .card-title {
    margin-bottom: 23px;
    margin-top: 40px;

    @media (min-width: 768px) {
      margin-bottom: 38px;
    }

    @media (min-width: 1440px) {
      margin-bottom: 109px;
      margin-top: 65px;
    }

    h4 {
      color: #96999b;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
    }

    p {
      color: #96999b;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .card {
    position: relative;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      margin-bottom: 147px;
    }

    .image {
      height: 200px;
      width: calc(100% - 23px);

      @media (min-width: 768px) {
        height: 476px;
        width: calc(100% - 100px);
      }

      @media (min-width: 1440px) {
        width: calc(100% - 96px);
      }
    }

    .content {
      background-color: #fff;
      font-size: 16px;
      line-height: 30px;
      padding: 20px 30px;
      position: relative;
      top: -68px;
      margin-left: auto;
      width: calc(100% - 23px);

      @media (min-width: 768px) {
        bottom: -107px;
        padding: 40px 30px;
        position: absolute;
        top: unset;
        right: 0;
        width: calc(100% - 117.8px);
      }

      @media (min-width: 1440px) {
        width: calc(100% - 112px);
      }
    }
  }
`

export default Gallery
