import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { array } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'
//import Img from 'gatsby-image'

import MapApp from './components/MapStyle'

import TopIcon from '@images/icons/arrow-up.svg'
import BottomIcon from '@images/icons/arrow-down.svg'

const Slide = styled(({ items, images, className }) => {
  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentArrow, setCurrentArrow] = useState('first')
  const [location, setLocation] = useState({
    lat: items[0].location.lat,
    lng: items[0].location.lng
  })

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next)
      setLocation({
        lat: items[next].location.lat,
        lng: items[next].location.lng
      })

      if (next == 0) setCurrentArrow('first')
      else if (items.length / 2 == next + 1) setCurrentArrow('middle')
      else if (items.length == next + 1) setCurrentArrow('last')
      else {
        if (current < next) setCurrentArrow('first')
        else setCurrentArrow('last')
      }
    }
  }

  function next() {
    slider.current.slickNext()
  }
  function previous() {
    slider.current.slickPrev()
  }

  return (
    <div className={className}>
      <Row className="justify-content-xl-end">
        <Col md={6} xl={6}>
          <Row className="justify-content-xl-end">
            <Col md={3} xl={3} className="p-0">
              <div className={`arrows ${currentArrow}`}>
                <button className="prev" onClick={previous}>
                  <TopIcon />
                </button>
                <span>Chose</span>
                <button className="next" onClick={next}>
                  <BottomIcon />
                </button>
              </div>
            </Col>
            <Col md={9} xl={9} className="p-0">
              <Slider {...settings} ref={slider}>
                {items.map((item, index) => {
                  return (
                    <div className="slide" key={index}>
                      <div className="count-current">
                        <div className="current">{currentSlide + 1}</div>
                        <div className="count">/ {items.length}</div>
                      </div>
                      <div className="content-area">
                        <span className="h6">{item.subtitle}</span>
                        <h5>
                          {item.title}
                          <img
                            src={images[index].node.childImageSharp.fixed.src}
                            alt={item.title}
                          />
                        </h5>
                      </div>
                      {items[index + 1] && (
                        <div className="next">{items[index + 1].title}</div>
                      )}
                    </div>
                  )
                })}
              </Slider>
            </Col>
          </Row>
        </Col>
        <Col md={6} xl={6}>
          <div className="map">
            <MapApp location={location} />
          </div>
        </Col>
      </Row>
    </div>
  )
})`
  margin-bottom: 0;
  margin-top: 90px;

  .slide {
    .count-current {
      align-items: start;
      display: flex;
      margin-bottom: 26px;

      @media (min-width: 1440px) {
        margin-bottom: 105px;
      }

      .current {
        color: #d67401;
        font-family: Philosopher;
        font-weight: bold;
        font-size: 120px;
        line-height: 134px;
        letter-spacing: 0.01em;

        @media (min-width: 1440px) {
          font-size: 200px;
          line-height: 224px;
        }
      }

      .count {
        color: #434852;
        font-weight: bold;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0.01em;
        padding-top: 40px;
        margin-left: 36px;
      }
    }

    .content-area {
      display: block;

      .h6 {
        color: #000000;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.01em;
        line-height: 20px;
        margin-bottom: 12px;
      }

      h5 {
        align-items: center;
        color: #000000;
        display: flex;
        font-family: Philosopher;
        font-weight: bold;
        font-size: 32px;
        line-height: 36px;

        .gatsby-image-wrapper {
          margin-left: 20px;
        }

        @media (min-width: 1440px) {
          font-size: 40px;
          line-height: 45px;
        }
      }
    }

    .next {
      color: #96999b;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      margin-top: 56px;

      @media (min-width: 1440px) {
        font-size: 40px;
        line-height: 45px;
        margin-top: 85px;
      }
    }
  }

  .arrows {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
    margin: 50px auto 0;

    @media (min-width: 1440px) {
      margin: 65px auto 0;
    }

    button {
      background-color: transparent;
      border: none;
      display: block;
      width: 100%;

      &:hover {
        svg {
          path {
            &:first-of-type {
              fill: #d67401;
            }
            &:last-child {
              stroke: #d67401;
            }
          }
        }
      }
    }

    span {
      display: block;
      text-align: center;
      margin: 30px 0;
      width: 100%;
    }

    &.first button.next svg path {
      &:first-of-type {
        fill: #d67401;
      }
      &:last-child {
        stroke: #d67401;
      }
    }

    &.middle {
      button.prev,
      button.next {
        svg path {
          &:first-of-type {
            fill: #d67401;
          }
          &:last-child {
            stroke: #d67401;
          }
        }
      }
    }

    &.last button.prev svg path {
      &:first-of-type {
        fill: #d67401;
      }
      &:last-child {
        stroke: #d67401;
      }
    }
  }

  .slick-slider .slick-track {
    display: block !important;
    .slick-slide {
      display: block !important;
    }
  }

  .map {
    border-radius: 50%;
    height: 310px;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    width: 310px;

    @media (min-width: 1440px) {
      height: 640px;
      width: 640px;
    }
  }
`

Slide.propTypes = {
  items: array.isRequired,
  images: array.isRequired
}

Slide.defaultProps = {
  items: [],
  images: []
}

export default Slide
