import React from 'react'
import styled from '@emotion/styled'

// components
import Header from './Header'
import Footer from './Footer'
const Layout = styled(({ children, className }) => {
  return (
    <div className={className} id="main-layout">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
})`
  margin: 0 auto;
  overflow: hidden;
`
export default Layout
