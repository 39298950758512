import React from 'react'
import styled from '@emotion/styled'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Logo from '@images/logo.svg'
import LogoMobile from '@images/logo-mobile.svg'
import Favicon from '@images/favicon.svg'

const Brand = styled(({ className }) => {
  return (
    <button
      onClick={() => scrollTo(`#hero`)}
      className={`${className} position-relative p-0 brand`}
    >
      <div className="logo">
        <img src={Logo} alt="logo" className="d-none d-md-block" />
        <img src={LogoMobile} alt="logo" className="d-md-none" />
      </div>
      <div className="favicon">
        <img src={Favicon} alt="logo" />
      </div>
    </button>
  )
})`
  background: transparent;
  border: none;

  .logo {
    img {
      width: 140px;

      @media (min-width: 768px) {
        width: 272px;
      }
    }
  }

  .favicon {
    display: none;
  }
`

export default Brand
