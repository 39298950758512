import React from 'react'
import styled from '@emotion/styled'

// components
import Cookie from './components/Cookies'
import NavBar from './components/NavBar'

import { HeaderDataContent } from './models'

const Header = styled(({ className }) => {
  const cookies = HeaderDataContent()[0]
  const navbar = HeaderDataContent()[1]

  return (
    <div className={`${className}`}>
      <Cookie data={cookies} />
      <header>
        <NavBar data={navbar} />
      </header>
    </div>
  )
})`
  width: 100%;
  z-index: 99;
`

export default Header
