import { useStaticQuery, graphql } from 'gatsby'

export const ProximityDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allProximityDataJson, images, images_map } = useStaticQuery(
    graphql`
      query queryProximityDataContent {
        allProximityDataJson {
          edges {
            node {
              main_text {
                title
                content
              }
              items {
                title
                subtitle
                content
                link
                location {
                  lat
                  lng
                }
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "proximity/icons" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 31) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        images_map: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "proximity/map" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allProximityDataJson.edges[0].node.main_text,
    allProximityDataJson.edges[0].node.items,
    images.edges,
    images_map.edges
  ]
}
