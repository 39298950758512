import { useStaticQuery, graphql } from 'gatsby'

export const FooterDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allFooterDataJson } = useStaticQuery(
    graphql`
      query queryFooterDataContent {
        allFooterDataJson {
          edges {
            node {
              contact {
                address
                cta {
                  text
                  link
                }
              }
              copyright
              links {
                text
                link
              }
            }
          }
        }
      }
    `
  )
  return [
    allFooterDataJson.edges[0].node.contact,
    allFooterDataJson.edges[0].node.copyright,
    allFooterDataJson.edges[0].node.links
  ]
}
