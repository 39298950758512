import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'

import { FaqDataContent } from './models'
import AccordionBox from './components/AccordionBox'

const Faq = styled(({ className }) => {
  const mainText = FaqDataContent()[0]
  const items = FaqDataContent()[1]
  const images = FaqDataContent()[2]

  return (
    <div className={`${className}`}>
      <Container>
        <div className="accordion-list">
          <div className="title">
            <h3>{mainText.title}</h3>
          </div>
          <AccordionBox data={items} images={images} />
        </div>
      </Container>
    </div>
  )
})`
  position: relative;

  .accordion-list {
    @media (min-width: 1440px) {
      padding: 0 130px;
    }
  }

  .title {
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;

      @media (min-width: 768px) {
        margin-bottom: 24px;
      }

      @media (min-width: 1440px) {
        margin-bottom: 56px;
      }
    }
  }
`

export default Faq
