import React from 'react'
import styled from '@emotion/styled'
import { object, array } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { replaceStringWithDates } from '@scripts/helpers'

import Holiday from '@images/icons/holiday.svg'
import Tripadvisor from '@images/icons/tripadvisor.svg'

const LeftArea = styled(({ images, data, className }) => {
  const leftImages = images.slice(1)

  return (
    <div className={`${className}`}>
      <div className="hero-left">
        <div className="hero-content">
          <div className="hero-left-subtitle">
            <h6 dangerouslySetInnerHTML={{ __html: data.subtitle }}></h6>
            <Holiday className="holiday" />
          </div>
          <h1>{data.title}</h1>
          <p>{data.content}</p>
          <div className="button-area">
            <a
              href={replaceStringWithDates(data.cta.link)}
              target="_blank"
              rel="noreferrer"
            >
              {data.cta.text}
            </a>
            <Tripadvisor className="holiday" />
          </div>
        </div>
        <div className="d-xl-block d-none">
          <Row className="mr-0 ml-0">
            {leftImages.map((image, index) => {
              return (
                <Col xl={index < 2 ? 6 : 12} className="p-0" key={index}>
                  <div
                    className="hero-image background-image"
                    style={{
                      backgroundImage: `url(${image.node.childImageSharp.fluid.srcWebp})`
                    }}
                  ></div>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </div>
  )
})`
  position: relative;

  .hero-left {
    @media (min-width: 1200px) {
      padding-right: 8px;
    }

    .hero-content {
      padding-left: 12px;

      h6 {
        color: #000000;
        display: inline-block;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        letter-spacing: 0.01em;
        margin: 0;

        .font-bold {
          font-weight: 800;
        }

        @media (min-width: 1440px) {
          font-size: 20px;
        }
      }

      svg.holiday {
        margin-left: 16px;
      }

      h1 {
        color: #d64a00;
        font-family: Philosopher;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 52px;
        margin: 24px 0;

        @media (min-width: 768px) {
          margin: 32px 0 24px;
        }

        @media (min-width: 1200px) {
          font-size: 72px;
          line-height: 72px;
          width: 85%;
        }

        @media (min-width: 1450px) {
          width: 70%;
        }
      }

      p {
        color: #434852;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.01em;

        @media (min-width: 1440px) {
          width: 90%;
        }
      }

      .button-area {
        margin-bottom: 32px;

        a {
          background: #d67401;
          border: none;
          color: #ffffff;
          display: inline-block;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 52px;
          padding: 0 33px;

          &:hover {
            background: #d64a00;
          }
        }

        svg {
          margin-left: 30px;
        }
      }
    }

    .hero-image {
      margin: 8px 4px 0;
    }
  }

  .hero-image {
    height: 113px;

    &.middle {
      margin: 0 4px;
    }

    @media (min-width: 1440px) {
      height: 187px;
    }
  }
`

LeftArea.propTypes = {
  data: object,
  images: array
}

LeftArea.defaultProps = {
  data: {},
  images: []
}

export default LeftArea
