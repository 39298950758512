import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
//import Img from 'gatsby-image'

import { EquippedDataContent } from './models'

const Equipped = styled(({ className }) => {
  const mainText = EquippedDataContent()[0]
  const items = EquippedDataContent()[1]
  const images = EquippedDataContent()[2]

  return (
    <div className={`${className}`}>
      <Container>
        <div className="title">
          <h3>{mainText.title}</h3>
          <p>{mainText.content}</p>
        </div>
        <Row>
          {items.map((item, index) => {
            return (
              <Col xs={12} md={6} xl={4} key={index}>
                <div
                  className={`item ${index == items.length - 1 ? 'last' : ''}`}
                >
                  <div className="image">
                    <img
                      src={images[index].node.childImageSharp.fixed.src}
                      alt={item.title}
                    />
                  </div>
                  <h6>{item.title}</h6>
                  <div className="content">{item.content}</div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
})`
  position: relative;

  .title {
    h3 {
      color: #d64a00;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 52px;
      }

      @media (min-width: 1440px) {
        font-size: 62px;
        line-height: 72px;
      }
    }

    p {
      color: #434852;
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 40px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .item {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    margin-bottom: 60px;

    @media (min-width: 1440px) {
      margin-bottom: 99px;
    }

    &.last {
      margin-bottom: 0;
    }

    .image {
      height: 59px;
      flex: 0 0 59px;
    }

    h6 {
      color: #000000;
      flex: 0 0 calc(100% - 59px);
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      padding-left: 16px;
      margin: 0;
      max-width: calc(100% - 59px);

      @media (min-width: 1440px) {
        margin-bottom: 20px;
        padding-left: 25px;
        padding-top: 11px;
      }
    }

    .content {
      color: #434852;
      flex: 0 0 100%;
      font-size: 18px;
      letter-spacing: 0.01em;
      line-height: 28px;
      margin-top: 24px;
      max-width: 100%;

      @media (min-width: 1440px) {
        padding-left: 84px;
        margin-top: 0;
      }
    }
  }
`

export default Equipped
