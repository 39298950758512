import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import { Container } from 'react-bootstrap'
import CookieConsent, { Cookies } from 'react-cookie-consent'

import CloseIcon from '@images/icons/close.svg'

const Cookie = styled(({ data, className }) => {
  const [closeCookie, setCloseCookie] = useState(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted ? (
    <div>
      {Cookies.get('gatsby-gdpr') ? null : (
        <div className={`${className} ${closeCookie ? '' : 'show'}`}>
          <Container>
            <CookieConsent
              disableStyles={true}
              buttonText={data.acceptButton}
              buttonWrapperClasses="button-wrapper"
              contentClasses="content-wrapper"
              onAccept={() => {
                setCloseCookie(!closeCookie)
              }}
              flipButtons
              cookieName="gatsby-gdpr"
            >
              <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
            </CookieConsent>
          </Container>
          <button
            className="close-icon-btn"
            onClick={() => {
              setCloseCookie(!closeCookie)
            }}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  ) : null
})`
  background-color: #000000;
  color: #fff;
  display: none;
  padding: 30px 0;
  position: relative;
  text-align: left;
  transition: all 0.2s ease-out;

  @media (min-width: 768px) {
    padding: 20px 0;
  }

  @media (min-width: 1440px) {
    padding: 11px 0;
  }

  &.show {
    display: block !important;
  }

  .CookieConsent {
    @media (min-width: 768px) {
      align-items: center;
      display: flex;
    }

    @media (min-width: 1200px) {
      justify-content: center;
    }

    .content-wrapper {
      display: inline-block;

      @media (min-width: 768px) {
        width: calc(100% - 200px);
      }

      @media (min-width: 1200px) {
        width: auto;
      }
    }

    .button-wrapper {
      display: inline-block;
    }

    p {
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      a {
        color: #fff;
      }

      @media (min-width: 768px) {
        flex: 1;
      }

      @media (min-width: 1440px) {
        width: auto;
      }
    }

    button {
      background-color: transparent;
      border: none;
      color: #ffffff;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      padding: 0;
      margin-top: 20px;

      @media (min-width: 768px) {
        margin-left: 83px;
        margin-right: 0;
        margin-top: 0;
      }

      @media (min-width: 1440px) {
        margin-left: 40px;
        margin-right: 0;
      }
    }
  }

  .close-icon-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
  }
`

Cookie.propTypes = {
  data: object.isRequired
}

Cookie.defaultProps = {
  data: {}
}

export default Cookie
