import React, { useRef, useState } from 'react'
import { array } from 'prop-types'
import styled from '@emotion/styled'
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
//import BackgroundImage from 'gatsby-background-image'

import LeftIcon from '@images/icons/arrow-left.svg'
import RightIcon from '@images/icons/arrow-right.svg'

const Slide = styled(({ images, data, className }) => {
  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentArrow, setCurrentArrow] = useState('first')

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCurrentSlide(next)

      if (next == 0) setCurrentArrow('first')
      else if (images.length / 2 == next + 1) setCurrentArrow('middle')
      else if (images.length == next + 1) setCurrentArrow('last')
      else {
        if (current < next) setCurrentArrow('first')
        else setCurrentArrow('last')
      }
    }
  }

  function next() {
    slider.current.slickNext()
  }
  function previous() {
    slider.current.slickPrev()
  }

  const leftImages = images.slice(1)

  return (
    <div className={`${className}`}>
      <div className="hero-bg-wrapper">
        <Slider {...settings} ref={slider}>
          {images.map((image, index) => {
            return (
              <div key={index}>
                <div
                  className="hero-bg background-image"
                  style={{
                    backgroundImage: `url(${image.node.childImageSharp.fluid.srcWebp})`
                  }}
                />
              </div>
            )
          })}
        </Slider>
        <div className="hero-bg-text">
          {data.map((item, index) => {
            return <div key={index}>{item.text}</div>
          })}
        </div>
      </div>
      <div className="d-none d-md-block d-xl-none">
        <Row className="m-0">
          {leftImages.map((image, index) => {
            return (
              <Col md="4" className="p-0" key={index}>
                <div
                  className={`hero-image background-image ${
                    index == 1 ? 'middle' : ''
                  }`}
                  style={{
                    backgroundImage: `url(${image.node.childImageSharp.fluid.srcWebp})`
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </div>
      <div className="current-arrows">
        <div className="current">
          <span className="current-span">{currentSlide + 1}</span> /{' '}
          <span className="length-span">{images.length}</span>
        </div>
        <div className={`arrows ${currentArrow}`}>
          <button className="prev" onClick={previous}>
            <LeftIcon />
          </button>
          <button className="next" onClick={next}>
            <RightIcon />
          </button>
        </div>
      </div>
    </div>
  )
})`
  height: 100%;

  .hero-bg-wrapper {
    height: 241px;
    position: relative;

    @media (min-width: 768px) {
      height: 335px;
    }

    @media (min-width: 1200px) {
      height: 100%;
    }

    .slick-slider,
    .slick-slider div {
      display: block !important;
      height: 100%;
    }

    .slick-slide {
      margin: 0;
    }

    .hero-bg-text {
      bottom: 24px;
      display: none;
      position: absolute;
      left: 24px;
      z-index: 2;

      @media (min-width: 768px) {
        display: block;
      }

      div {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        color: #ffffff;
        display: inline-block;
        font-size: 14px;
        line-height: 30px;
        padding: 5px 10px;
        margin-right: 20px;
      }
    }

    .hero-bg {
      height: 241px;

      @media (min-width: 768px) {
        height: 335px;
        margin-bottom: 4px;
      }

      @media (min-width: 1200px) {
        height: 100%;
        margin-bottom: 0;
      }
    }
  }

  .hero-image {
    height: 113px;
    margin-top: 4px;

    &.middle {
      margin: 4px 4px 0;
    }

    @media (min-width: 1440px) {
      height: 187px;
    }
  }

  .current-arrows {
    align-items: center;
    display: flex;
    margin-top: 28px;

    @media (min-width: 768px) {
      justify-content: end;
    }

    @media (min-width: 1200px) {
      margin-right: calc((100vw - 1140px + 120px) / 2);
    }

    @media (min-width: 1450px) {
      margin-right: calc((100vw - 1344px + 120px) / 2);
    }

    .current {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.01em;
      line-height: 30px;
      margin-right: 40px;

      span.current-span {
        font-weight: 700;
      }

      span.length-span {
        color: #96999b;
      }
    }

    .arrows {
      button {
        background: transparent;
        border: none;

        &:hover {
          svg {
            path {
              &:first-of-type {
                fill: #d67401;
              }
              &:last-child {
                stroke: #d67401;
              }
            }
          }
        }
      }
    }
  }
`

Slide.propTypes = {
  data: array,
  images: array
}

Slide.defaultProps = {
  data: [],
  images: []
}

export default Slide
