import { useStaticQuery, graphql } from 'gatsby'

export const HeaderDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allHeaderDataJson } = useStaticQuery(
    graphql`
      query queryHeaderDataContent {
        allHeaderDataJson {
          edges {
            node {
              cookies {
                text
                acceptButton
              }
              navbar {
                cta {
                  text
                  link
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allHeaderDataJson.edges[0].node.cookies,
    allHeaderDataJson.edges[0].node.navbar
  ]
}
