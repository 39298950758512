import { useStaticQuery, graphql } from 'gatsby'

export const EquippedDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allEquippedDataJson, images } = useStaticQuery(
    graphql`
      query queryEquippedDataContent {
        allEquippedDataJson {
          edges {
            node {
              main_text {
                title
                content
              }
              items {
                title
                content
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "equippeds" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 59) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allEquippedDataJson.edges[0].node.main_text,
    allEquippedDataJson.edges[0].node.items,
    images.edges
  ]
}
