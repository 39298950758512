import moment from 'moment-timezone'

moment.tz.setDefault('America/Costa_Rica')

export const replaceStringWithDates = (link) => {
  //const today = moment()
  const arriveDate = '2022-01-02' //moment(today).add(1, 'days').format('YYYY-MM-DD')
  const departDate = '2022-01-09' //moment(today).add(2, 'days').format('YYYY-MM-DD')
  const searchStr = {
    '[arrive_date]': arriveDate,
    '[depart_date]': departDate
  }
  const newLink = link.replace(
    /\[arrive_date\]|\[depart_date\]/gi,
    (matched) => {
      return searchStr[matched]
    }
  )

  return newLink
}
