import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'

import { ProximityDataContent } from './models'

import Cards from './components/Cards'
import Slide from './components/Slide'

const Proximity = styled(({ className }) => {
  const mainText = ProximityDataContent()[0]
  const items = ProximityDataContent()[1]
  const images = ProximityDataContent()[2]

  return (
    <div className={`${className}`}>
      <Container>
        <div className="title">
          <h3>{mainText.title}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: mainText.content }}
            className="d-none d-md-block"
          ></p>
        </div>
        <div className="d-none d-md-block">
          <Slide items={items} images={images} />
        </div>
        <div className="d-md-none">
          <Cards items={items} images={images} />
        </div>
      </Container>
    </div>
  )
})`
  position: relative;
  margin-top: 44px;

  .title {
    h3 {
      color: #d64a00;
      font-family: Philosopher;
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 51px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 52px;
      }

      @media (min-width: 1440px) {
        font-size: 62px;
        line-height: 72px;
      }
    }

    p {
      color: #434852;
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 40px;

      .font-bold {
        font-weight: 800;
      }

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
  }

  .slide {
  }

  .item {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 26px;
    margin-bottom: 45px;

    &.last {
      margin-bottom: 0;
    }

    h6 {
      align-items: center;
      color: #000000;
      display: flex;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin: 0;

      img {
        height: 31px;
        flex: 0 0 31px;
        margin-left: 8px;
      }
    }

    .content {
      color: #434852;
      flex: 0 0 100%;
      font-size: 18px;
      letter-spacing: 0.01em;
      line-height: 28px;
      margin: 16px 0 26px;
      max-width: 100%;
    }

    a {
      color: #434852;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      img {
        margin-left: 8px;
      }
    }
  }
`

export default Proximity
