import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { replaceStringWithDates } from '@scripts/helpers'

import { FooterDataContent } from './models'

import Logo from '@images/logo.svg'
import LogoMobile from '@images/logo-mobile.svg'

import Near from '@images/icons/near.svg'

const Footer = styled(({ className }) => {
  const contact = FooterDataContent()[0]
  const copyright = FooterDataContent()[1]
  const links = FooterDataContent()[2]

  return (
    <div className={`${className}`}>
      <footer>
        <Container>
          <Row>
            <Col xs="12" md="5">
              <div className="logo d-none d-xl-block">
                <img src={Logo} alt="logo" />
              </div>
              <div className="logo-mobile d-xl-none">
                <img src={LogoMobile} alt="logo" />
              </div>
            </Col>
            <Col xs="12" md="7">
              <div className="footer-contact text-md-right">
                <div>
                  <Near className="near" />
                  <span>{contact.address}</span>
                </div>
                <a
                  href={replaceStringWithDates(contact.cta.link)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contact.cta.text}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="footerText">
        <Container>
          <Row className="align-items-center">
            <Col sm="6" xl="4" className="copyright-wrap">
              <div className="copyright">{copyright}</div>
            </Col>
            <Col xs="12" sm="6" xl="8">
              <ul>
                {links.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        {item.text}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  margin-top: 86px;

  @media (min-width: 1440px) {
    margin-top: 128px;
  }

  footer {
    background: #fff;
    padding: 0 0 60px;

    @media (min-width: 768px) {
      padding: 0 0 29px;
    }

    @media (min-width: 1440px) {
      padding: 0 0 24px;
    }

    .logo-mobile {
      text-align: center;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        text-align: left;
      }
    }

    .footer-contact {
      display: flex;
      flex-flow: column;

      @media (min-width: 1440px) {
        flex-flow: row;
        justify-content: end;
      }

      a {
        background: #d67401;
        border: none;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 52px;
        padding: 0 33px;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;

        @media (min-width: 1440px) {
          margin-bottom: 0;
          width: auto;
        }
      }

      div {
        align-items: center;
        display: flex;
        order: 2;

        @media (min-width: 1440px) {
          order: 0;
          margin-right: 32px;
        }

        &:hover {
          .near path {
            fill: #d64a00;
          }
          span {
            color: #d64a00;
          }
        }

        .near {
          display: inline-block;
          margin-right: 11px;
        }

        span {
          color: #000000;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .footerText {
    background: #f2f1ec;
    padding: 30px 0;

    @media (min-width: 1440px) {
      padding: 39px 0 30px;
    }

    .copyright-wrap {
      order: 2;

      @media (min-width: 768px) {
        order: 0;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }

      li {
        display: inline-block;

        &:after {
          content: '|';
          margin: 0 7px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        a {
          color: #000000;
          font-size: 16px;
          line-height: 20px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .copyright {
      color: #434852;
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      margin: 0;

      @media (min-width: 768px) {
        text-align: left;
      }
    }
  }
`

export default Footer
