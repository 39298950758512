import { useStaticQuery, graphql } from 'gatsby'

export const FaqDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allFaqDataJson } = useStaticQuery(
    graphql`
      query queryFaqDataContent {
        allFaqDataJson {
          edges {
            node {
              main_text {
                title
              }
              items {
                title
                content
              }
            }
          }
        }
      }
    `
  )
  return [
    allFaqDataJson.edges[0].node.main_text,
    allFaqDataJson.edges[0].node.items
  ]
}
