import { useStaticQuery, graphql } from 'gatsby'

export const HeroDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allHeroDataJson, images } = useStaticQuery(
    graphql`
      query queryHeroDataContent {
        allHeroDataJson {
          edges {
            node {
              main_text {
                subtitle
                title
                content
                cta {
                  text
                  link
                }
              }
              banner_text {
                text
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "hero" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 868, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allHeroDataJson.edges[0].node.main_text,
    allHeroDataJson.edges[0].node.banner_text,
    images.edges
  ]
}
