import React from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import { replaceStringWithDates } from '@scripts/helpers'

import Calender from '@images/icons/calender.svg'

const Navigation = styled(({ data, className }) => {
  return (
    <div className={`${className} navigation-items`}>
      <ul>
        {/*
        {data.navigation.map((item, index) => {
          return (
            <li key={index}>
              <button onClick={() => scrollTo(`#` + item.slug)}>
                {item.title}
              </button>
            </li>
          )
        })}
        */}
        <li className="get-help">
          <a
            href={replaceStringWithDates(data.cta.link)}
            target="_blank"
            rel="noreferrer"
          >
            <Calender />
            {data.cta.text}
          </a>
        </li>
      </ul>
    </div>
  )
})`
  ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      margin-right: 40px;

      &:nth-last-of-type(2) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-left: 40px;
      }

      a,
      button {
        background: transparent;
        border: none;
        color: #231e60;
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 18px 0;
        text-transform: uppercase;

        &:hover {
          color: #63a536;
        }
      }

      &.get-help {
        a {
          align-items: center;
          border-bottom: 3px solid #d67401;
          color: #434852;
          display: flex;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 52px;
          text-transform: uppercase;
          padding: 0;

          svg {
            margin-right: 10px;
          }

          &:hover {
            border-color: #d64a00;
          }
        }
      }
    }
  }
`

Navigation.propTypes = {
  data: object.isRequired
}

Navigation.defaultProps = {
  data: {}
}

export default Navigation
