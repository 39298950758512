import React, { useState } from 'react'
import { node } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const ThemeProvider = ({ children }) => {
  const toggleTab = (first) => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activeTab: first
      }
    })
  }

  const loadingState = {
    activeTab: 0,
    toggleTab
  }

  const [open, setOpen] = useState(loadingState)

  return <ThemeContext.Provider value={open}>{children}</ThemeContext.Provider>
}

ThemeProvider.propTypes = {
  children: node
}

ThemeProvider.defaultProps = {
  children: null
}

export default ThemeProvider
