import React from 'react'
import { string } from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

// const SEO = ({ title, subTitle, description }) => {
const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaTitle = title || site.siteMetadata.title
  // const metaSubTitle = subTitle || site.siteMetadata.subTitle
  const metaDescription = description || site.siteMetadata.description

  //titleTemplate={`%s | ${metaSubTitle} `}

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        }
      ]}
    ></Helmet>
  )
}

SEO.propTypes = {
  title: string,
  description: string
}

SEO.defaultProps = {
  title: null,
  description: null
}

export default SEO
