import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'
import { replaceStringWithDates } from '@scripts/helpers'

import { QuoteDataContent } from './models'

import quoteDesktop from '@images/background/quote-desktop.png'
import quoteDesktopBig from '@images/background/quote-desktop-big.png'
import quoteTablet from '@images/background/quote-tablet.png'
import quoteMobile from '@images/background/quote-mobile.png'

const Quote = styled(({ className }) => {
  const mainText = QuoteDataContent()[0]

  return (
    <div className={`${className}`}>
      <Container>
        <div className="box">
          <h3>{mainText.title}</h3>
          <p>{mainText.content}</p>
          <a
            href={replaceStringWithDates(mainText.cta.link)}
            target="_blank"
            rel="noreferrer"
          >
            {mainText.cta.text}
          </a>
        </div>
      </Container>
    </div>
  )
})`
  position: relative;
  margin: 50px 0;

  @media (min-width: 768px) {
    margin: 80px 0;
  }

  @media (min-width: 1440px) {
    margin: 128px 0 160px;
  }

  .container {
    padding: 0;
    max-width: none;
  }

  .box {
    background-image: url('${quoteMobile}');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 46px 45px;
    text-align: center;

    @media (min-width: 768px) {
      background-image: url('${quoteTablet}');
      background-size: cover;
      background-position: center center;
      padding: 89px 96px 77px 124px;
    }

    @media (min-width: 1200px) {
      background-image: url('${quoteDesktop}');
      background-position: center right;
      padding: 68px 135px 96px;
    }

    @media (min-width: 1920px) {
      background-image: url('${quoteDesktopBig}');
      background-position: center right;
      padding: 68px 272px 96px 331px;
    }

    h3 {
      color: #434852;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
    }

    p {
      color: #434852;
      font-family: Philosopher;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 26px;
      margin: 24px 0;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 46px;
        margin: 32px 0;
      }

      @media (min-width: 1440px) {
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
      }
    }

    a {
      background: #d67401;
      border: none;
      color: #ffffff;
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 52px;
      text-transform: uppercase;
      text-align: center;
      padding: 0 33px;

      &:hover {
        background: #d64a00;
      }
    }
  }
`

export default Quote
