import React, { Component } from 'react'
import { object } from 'prop-types'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
export class MapContainer extends Component {
  render() {
    return (
      <Map google={this.props.google} zoom={15} center={this.props.location}>
        <Marker position={this.props.location} />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDNTQrQIo0hj4FIGXZ7XSxav1uarmsUPTo',
  map: 'satellite'
})(MapContainer)

MapContainer.propTypes = {
  location: object
}

MapContainer.defaultProps = {
  location: {}
}
