import React from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'

// components
import Brand from './components/Brand'
import Navigation from './components/Navigation'

const NavBar = styled(({ data, className }) => {
  return (
    <div className={`${className} bg-white top-nav-wrapper`}>
      <Container>
        <Row>
          <Col>
            <div className="d-flex align-items-center bg-white navigation-content-top">
              <Brand />
              <Navigation data={data} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background-color: #fff;
  padding: 20px 0 19px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 25px 0 24px;
  }

  @media (min-width: 1200px) {
    padding: 24px 0 40px;
  }

  .navigation-content-top {
    justify-content: space-between;
  }
`

NavBar.propTypes = {
  data: object.isRequired
}

NavBar.defaultProps = {
  data: {}
}

export default NavBar
