import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'

import { HeroDataContent } from './models'

import LeftArea from './components/LeftArea'
import Slide from './components/Slide'

const Hero = styled(({ className }) => {
  const mainContent = HeroDataContent()[0]
  const bannerContent = HeroDataContent()[1]
  const heroImages = HeroDataContent()[2]

  return (
    <section className={`${className} hero`}>
      <div id="hero" className="scroll-target"></div>
      <Container className="container-fluid-right p-xl-0">
        <Row>
          <Col md="12" xl="4" xxl="3" className="p-xl-0">
            <LeftArea data={mainContent} images={heroImages} />
          </Col>
          <Col md="12" xl="8" xxl="9" className="p-xl-0">
            <Slide images={heroImages} data={bannerContent} />
          </Col>
        </Row>
      </Container>
    </section>
  )
})`
  position: relative;

  .scroll-target {
    position: absolute;
    top: -200px;
  }
`

export default Hero
