import React from 'react'
import styled from '@emotion/styled'
import { array } from 'prop-types'
import { Row, Col } from 'react-bootstrap'
//import Img from 'gatsby-image'

import rightIcon from '@images/right-icon.svg'

const Cards = styled(({ items, images, className }) => {
  return (
    <div className={className}>
      <Row>
        {items.map((item, index) => {
          return (
            <Col xs={12} md={6} xl={4} key={index}>
              <div
                className={`item ${index == items.length - 1 ? 'last' : ''}`}
              >
                <h6>
                  <span>{item.title}</span>
                  <img
                    src={images[index].node.childImageSharp.fixed.src}
                    alt={item.title}
                  />
                </h6>
                <div className="content">{item.content}</div>
                <a href={item.link} target="_blank" rel="noreferrer">
                  GET DIRECTIONS <img src={rightIcon} alt="Right Icon" />
                </a>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
})``

Cards.propTypes = {
  items: array.isRequired,
  images: array.isRequired
}

Cards.defaultProps = {
  items: {},
  images: {}
}

export default Cards
