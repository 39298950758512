import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { Accordion, Card, Button } from 'react-bootstrap'
import { array } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const AccordionBox = styled(({ data, className }) => {
  const { activeTab, toggleTab } = useContext(ThemeContext)

  const [currentActiveKey, setCurrentActiveKey] = useState(1)

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key)
  }

  const [CurrentActive, setCurrentActive] = useState(true)

  const toggleActive = (key) => {
    setCurrentActive(!CurrentActive)
    if (key !== activeTab) {
      setCurrentActive(true)
      toggleTab(key)
      setCurrentActiveKey(currentActiveKey === key)
    }
  }

  return (
    <div className={className}>
      <Accordion
        defaultActiveKey={activeTab}
        activeKey={CurrentActive === true ? activeTab + 1 : currentActiveKey}
      >
        {data.map((item, index) => {
          return (
            <Card
              key={index}
              className={
                activeTab !== 0 &&
                activeTab - 1 === index &&
                CurrentActive === true
                  ? 'prevActive'
                  : null
              }
            >
              <Card.Header
                className={
                  activeTab === index && CurrentActive === true
                    ? 'active'
                    : null
                }
              >
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={index + 1}
                  onClick={() => {
                    toggleActive(index)
                    toggleActiveKey(index + 1)
                    toggleTab(index)
                  }}
                >
                  <span>{item.title}</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index + 1}>
                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    </div>
  )
})`
  .accordion {
    .card {
      border-bottom: 1px solid #d6d4cf;

      &.prevActive {
        .card-header button span {
          border: none;
        }
      }

      &:last-child {
        border: none;

        .card-header {
          button {
            span {
              border: none;
            }
          }
        }
      }

      .card-header {
        position: relative;

        &.active {
          button {
            border: none;
            color: #d64a00;
            font-weight: bold;

            &:before {
              transform: translateY(-50%) rotate(-45deg);
            }

            &:after {
              transform: translateY(-50%) rotate(-45deg);
            }

            span {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }

        button {
          border: none;
          color: #141414;
          font-style: normal;
          font-family: Philosopher;
          font-style: normal;
          font-weight: bold;
          font-size: 24px !important;
          line-height: 30px !important;
          height: auto !important;
          text-align: left;
          padding: 32px 59px 32px 0 !important;
          position: relative;
          width: 100%;

          &:before,
          &:after {
            background: #434852;
            content: '';
            position: absolute;
          }

          &:before {
            height: 24px;
            right: 26px;
            width: 2px;
            top: 50%;
            transform: translateY(-50%);

            @media (min-width: 768px) {
              height: 28px;
              right: 30px;
            }
          }

          &:after {
            height: 2px;
            right: 15px;
            width: 24px;
            top: 50%;
            transform: translateY(-50%);

            @media (min-width: 768px) {
              right: 17px;
              width: 28px;
            }
          }

          span {
            display: block;
            width: 100%;
          }

          &:focus,
          &:hover {
            box-shadow: none;
            text-decoration: none;
            outline: none;
          }

          @media (min-width: 768px) {
            font-size: 32px !important;
            line-height: 40px !important;
            padding: 32px 112px 32px 0 !important;
          }

          @media (min-width: 1440px) {
            font-size: 48px !important;
            line-height: 56px !important;
            padding: 32px 112px 32px 0 !important;
          }
        }
      }

      .card-body {
        color: #434852;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        letter-spacing: 0.01em;
        line-height: 24px;
        padding-bottom: 32px;
      }
    }
  }

  .fade {
    display: none;

    &.active {
      display: block;
    }
  }
`

AccordionBox.propTypes = {
  data: array.isRequired
}

AccordionBox.defaultProps = {
  data: {}
}

export default AccordionBox
