import { useStaticQuery, graphql } from 'gatsby'

export const PromoDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allPromoDataJson } = useStaticQuery(
    graphql`
      query queryPromoDataContent {
        allPromoDataJson {
          edges {
            node {
              main_text {
                title
                subtitle
                content
                cta {
                  text
                  link
                }
              }
            }
          }
        }
      }
    `
  )
  return [allPromoDataJson.edges[0].node.main_text]
}
